<template>
  <div class="full-width py-4" style="max-width: 850px">
    <v-card rounded="lg" outlined>
      <v-card-text>
        <v-form
          @submit.prevent="onSubmit"
          class="d-flex align-center"
          style="gap: 10px"
        >
          <cz-input
            v-model="note"
            :placeholder="$t('customer.orders.addNote')"
            :rows="1"
            no-resize
            auto-grow
            hide-details
          />

          <cz-button
            :icon-src="mdiPlusCircleOutline"
            :rounded="false"
            text
            :title="$t('common.add')"
            :icon-size="24"
            :disabled="!note"
            type="submit"
          />
        </v-form>
      </v-card-text>
    </v-card>

    <v-hover
      v-slot:default="{ hover }"
      v-for="(item, index) of items"
      :key="`note_${index}`"
      :disabled="item.userId !== currentUser._id"
    >
      <v-card
        :hover="item.userId === currentUser._id"
        outlined
        class="mt-4"
        rounded="lg"
      >
        <v-card-text
          class="d-flex justify-space-between align-end"
          style="gap: 10px"
        >
          <div class="d-flex" style="gap: 20px">
            <cz-avatar
              :name="item.user.fullName"
              :src="
                item.user.profilePicture
                  ? item.user.profilePicture.url
                  : undefined
              "
            />
            <div class="d-flex flex-column" style="gap: 10px">
              <div class="text-body-1 textPrimary--text text-pre-wrap">
                {{ item.text }}
              </div>
              <div>
                {{
                  $t('common.createdAtAndBy', [
                    item.user.fullName,
                    formatDateTimeToDisplay(item.createdAt)
                  ])
                }}
              </div>
            </div>
          </div>
          <cz-button
            :icon-src="mdiTrashCanOutline"
            color="negative"
            text
            @click="$emit('on-delete', item)"
            v-if="hover"
          />
        </v-card-text>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import { CzButton, CzInput, CzAvatar, CzFormField } from '@/components';
import { mdiPlusCircleOutline, mdiTrashCanOutline } from '@mdi/js';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
export default {
  name: 'DocumentNotes',
  components: { CzButton, CzInput, CzAvatar, CzFormField },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    currentUser: {
      type: Object
    }
  },
  data() {
    return {
      mdiPlusCircleOutline,
      mdiTrashCanOutline,
      note: ''
    };
  },
  methods: {
    formatDateTimeToDisplay,
    onSubmit() {
      this.$emit('on-add', this.note);
      this.note = '';
    }
  }
};
</script>

<style lang="scss" scoped></style>
