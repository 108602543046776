import { render, staticRenderFns } from "./OrderDecisionPage.vue?vue&type=template&id=53a71059&scoped=true"
import script from "./OrderDecisionPage.vue?vue&type=script&lang=js"
export * from "./OrderDecisionPage.vue?vue&type=script&lang=js"
import style0 from "./OrderDecisionPage.vue?vue&type=style&index=0&id=53a71059&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a71059",
  null
  
)

export default component.exports